import { hash } from 'ohash'
import { ElMessage } from 'element-plus'
const oRequest = function (url, requestoptions) {
  const runtimeConfig = useRuntimeConfig()
  console.log('request', url, requestoptions.params?._rawValue)
  console.log('cookie', JSON.stringify(useCookie('SDT_DeviceId')))
  requestoptions.params = requestoptions.params || {}
  requestoptions.params.rqHash = hash(new Date().getTime())
  return $fetch(url, {
    onRequest({ request, options }) {
      const token = useCookie('SDT_AccessToken')
      const localeLang = useCookie('i18n_redirected').value
      let lang = 'zh_CN'
      if (localeLang) {
        if (localeLang == 'en') {
          lang = 'en_US'
        } else {
          lang = 'zh_CN'
        }
      }
      console.log('onRequest', url)
      // options = options.assign({ ...requestoptions })
      if (process.server) {
        options.baseURL = process.env.INTERNAL_API_BASE || runtimeConfig.public.apiBase
      } else {
        options.baseURL = process.env.NODE_ENV === 'development' ? '/api' : 'https://sdt-api.ok-skins.com/'
        // options.baseURL = process.env.NODE_ENV === 'development' ? '/api' : 'https://sdt-api-beta.ok-skins.com/'
      }

      options.headers = {
        'x-device-id': useCookie('SDT_DeviceId')._value || '',
        'x-device': 1,
        'x-app-version': '1.0.0',
        'access-token': token.value,
        'x-currency': useCookie('SDT_MonetaryUnit')._value?.currency || 'CNY',
        language: lang
      }
      console.log('headers', JSON.stringify(options.headers))
      options.noCache = true

      console.log('requestMethod=>>>>', requestoptions.method, requestoptions?.method.toLowerCase(), requestoptions.params)
      if (requestoptions?.method.toLowerCase() == 'post') {
        options.body = JSON.stringify(requestoptions.params?._rawValue || requestoptions.params)
        // console.log('options', options)
      } else {
        options.params = requestoptions.params?._rawValue || requestoptions.params
      }

      options.method = requestoptions?.method
      // options.lazy = true
      // console.log(1, request, options)
      // 设置请求头
    },
    onRequestError({ request, options, error }) {
      console.log('onRequestError', error)
      // 处理请求错误
      // ElMessage({
      //   message: JSON.stringify(error),
      //   type: 'error'
      // })
    },
    onResponse({ request, response, options }) {
      // console.log('onResponse', url, response?._data)
      if (response._data.errorCode !== undefined && response._data.errorCode !== 0) {
        if (response?._data.errorCode == 401) {
          const token = useCookie('SDT_AccessToken')
          const mySteamToken = useCookie('SDT_MySteamToken')
          const store = useNuxtStore()
          token.value = null
          mySteamToken.value = null
          store.setUserInfo({})
        } else {
          ElMessage({
            message: response._data.errorMsg ?? JSON.stringify(response._data),
            type: 'error'
          })
        }
        console.log('response error', response._data.errorCode)
        // response._data = response._data.data
      } else {
        // console.log('response successful', response._data)
        response._data = response._data.data
      }
      // 处理响应数据
      // localStorage.setItem('token', response._data.token)
    },
    onResponseError({ request, response, options }) {
      // 处理响应错误
    }
  })
}

export default oRequest
